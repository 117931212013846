<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight2  pb20">
      <div class="list"
           v-if="list.length > 0">
        <van-swipe-cell v-for="item in list"
                        :key="item.id">
          <div class="item text333 bbox">
            <div class="f14 text-cut">
              {{ item.machineType }} {{ item.typeName }}
            </div>
            <div class="f13">{{$t('my.viewingHours')}}：{{ item.viewTime }}</div>
            <div class="text999 f12">
              {{ item.startDt | dataFormat }} 至 {{ item.endDt | dataFormat }}
            </div>
          </div>
          <template #right>
            <van-button square
                        text="删除"
                        type="danger"
                        class="delete-button hp100"
                        @click="del(item)" />
          </template>
        </van-swipe-cell>
      </div>

      <!-- 这里的加载中的插件需要去入口文件引入vant组件 -->
      <van-loading type="spinner"
                   color="white"
                   size="40px"
                   style="position: fixed; top: 0; left: 0"
                   v-if="showLoading" />
      <!-- 结果为空 -->
      <div v-if="list.length == 0"
           class="kong positiona">
        <img src="../../assets/images/kong.png"
             alt=""
             width="150" />
        <div class="f15 textc mt15 text999">{{$t('my.noTestLogging')}}</div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer class="positionf footer"></Footer>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import Header from "../../components/commonHead.vue";
import Footer from "../../components/footer.vue";
export default {
  components: { Header, Footer },
  data () {
    return {
      list: [],
      currPage: 1,
      totalPage: 1,
      pageSize: 30,
      showLoading: false,
    };
  },
  methods: {
    // 删除
    del (item) {
      Dialog.confirm({
        title: "温馨提示",
        message: "确定删除吗？",
      })
        .then(() => {
          // on confirm
          var sendData = {};
          sendData.id = item.id;
          var that = this;
          this.$http.post("memberViews/deleteMemberView", sendData).then(function (res) {
            if (200 == res.data.code) {
              var index = that.list.indexOf(item);
              that.list.splice(index, 1);
              Toast("删除成功");
            }
          });

        })
        .catch(() => {
          // on cancel
          Toast("取消删除");
        });
    },
    onScroll () {
      // 可滚动容器的高度
      var innerHeight = document.querySelector(".BoxHeight2").clientHeight;
      // 屏幕尺寸高度
      var outerHeight = document.documentElement.clientHeight - 70;
      // 可滚动容器超出当前窗口显示范围的高度
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight <= (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
      if (outerHeight + scrollTop >= innerHeight) {
        // 加载更多操作
        this.currPage++;
        this.loadViewList();
      }
    },
    loadViewList () {
      this.showLoading = true;
      var sendData = {};
      sendData.memberCode = localStorage.getItem("memberCode");
      sendData.currPage = this.currPage;
      sendData.pageSize = this.pageSize;
      var that = this;
      this.$http
        .post("memberViews/queryMemberViewList", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            for (var i = 0; i < res.data.data.data.length; i++) {
              that.list.push(res.data.data.data[i]);
            }
            that.currPage = res.data.data.currPage;
            that.totalPage = res.data.data.pageCount;
            that.showLoading = false;
          }
        });
    },

  },
  created () {
    this.loadViewList();
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll);
  },
  mounted () {
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped>
.list {
  padding-bottom: 80px;
}
.item {
  padding: 12px;
  border-bottom: 1px solid #f5f5f5;
}
.item:last-of-type {
  border-bottom: none;
}
.footer {
  bottom: 0;
  left: 0;
  width: 100vw;
}
</style>